<template>
  <div class="flexile_wrap">
    <h2 class="flexa">视频面试</h2>
    <div class="record">
      <el-table
        :data="tableData"
        stripe
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
      >
        <el-table-column prop="jobObject.jobName" label="职位名称"> </el-table-column>
        <el-table-column prop="companyObject.companyName" label="公司名称"> </el-table-column>
        <el-table-column prop="reservationTime" label="视频面试时间"> </el-table-column>
        <el-table-column prop="reservationStatus" label="状态">
          <template slot-scope="scope">
            <p v-if="scope.row.reservationStatus == 0" class="p1">待确认</p>
            <p v-if="scope.row.reservationStatus == 1" class="p2">已接受</p>
            <p v-if="scope.row.reservationStatus == 2" class="p3">已拒绝</p>
          </template>
        </el-table-column>

        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <div class="flexs" v-if="scope.row.reservationStatus == 0">
              <el-button type="text" @click="handleClick(scope.row.id,1)"
                ><p class="p1 cursorP">接受</p></el-button
              >
              <el-button type="text" @click="handleClick(scope.row.id,2)"
                ><p class="p3 cursorP">拒绝</p></el-button
              >
            </div>
            <el-button
              type="text"
              @click="openVideo(scope.row.id)"
              v-if="scope.row.reservationStatus == 1"
              ><p class="p5 flexs">
                进入面试间
              </p></el-button
            >
            <el-button
              type="text"
              @click="handleDelete(scope.row.id)"
              v-if="scope.row.reservationStatus == 2"
              ><p class="p3 cursorP">删除</p></el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pages">
      <el-pagination
          background
          class="work_pagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="page.pageNum"
          :page-size="page.pageSize"
          layout="prev, pager, next, total, jumper"
          :total="page.total"
      ></el-pagination>
    </div>
    <video-invitation ref="video" :hide="isShow" @close="handleClose"></video-invitation>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page:{
        pageNum:1,
        pageSize:10,
        total:0
      },
      tableData: [],
      isShow:false,
    };
  },
  methods: {
   async info(){
      let res = await this.$axios.post('/api/app-jycy-henan/henan/video/reservation/dis/list',this.page);
      if (res.data.success){
        this.tableData = res.data.data.records;
        this.page.total = res.data.data.total;
      }
    },
    //接受或拒绝
    handleClick(id,reservationStatus) {
     this.$axios.get('/api/app-jycy-henan/henan/video/reservation/dis/deal',{
       id:id,
       reservationStatus:reservationStatus
     }).then(res =>{
       this.$message({
         message: res.data.msg,
         type: "success",
       });
       this.info();
     })
    },
    //开始视频
    openVideo(id){
     this.isShow = true;
     this.$refs.video.init(id);
   },
    //删除邀请
    handleDelete(id) {
      this.$axios.get('/api/app-jycy-henan/henan/video/reservation/dis/delete',{id:id}).then(res => {
        if (res.data.success) {
          this.$confirm("确定要进行此操作吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
              .then(() => {
                this.$message.success(res.data.msg);
                this.info();
              })
              .catch((err) => {
                console.log(err);
              });
        } else {
          this.$message.error(res.data.msg);
        }
      })
    },
    handleClose(){
     this.isShow = false;
    },
    //分页
    handleCurrentChange: function (val) {
      this.page.pageNum = val;
      window.scrollTo(0, 0);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
  },
  created() {
    this.info();
  }
};
</script>

<style lang="less" scoped>
@import "~assets/css/henan/flexbile";

</style>
